export default {
    configs:{
        3:[{"bias":320,"ex_id":3,"ex_name":"sit to stand unsupported","UI_live_bar":{"ROM_Joint":1,"angle_type":"none","ROM_Angles_1":90,"ROM_Angles_2":0},"corr_angles":{"(1, 2)":[40,130,130,40],"(3, 4)":[230,320,320,230]},"main_muscle":[1,2],"threshold_1":1,"threshold_2":5,"bad_threshold":0.15,"good_threshold":0.3}],
        50:[{"bias":0,"ex_id":50,"ex_name":"Weight Shifts on a Narrow Base of Support","UI_live_bar":{"ROM_Joint":4,"angle_type":"none","ROM_Angles_1":10,"ROM_Angles_2":55},"corr_angles":{"(3,4)":[260,235,235,260],"(2,0)":[285,300,300,285]},"main_muscle":[3,4],"threshold_1":1,"threshold_2":5,"bad_threshold":0.3,"good_threshold":0.8},{"bias":0,"ex_id":50,"ex_name":"Weight Shifts on a Narrow Base of Support","UI_live_bar":{"ROM_Joint":1,"angle_type":"none","ROM_Angles_1":10,"ROM_Angles_2":55},"corr_angles":{"(2,1)":[245,235,235,245],"(5,3)":[105,120,120,105]},"main_muscle":[2,1],"threshold_1":1,"threshold_2":5,"bad_threshold":0.3,"good_threshold":0.8}],
        52:[{"bias":100,"ex_id":52,"ex_name":"Core Strengthening, Knees to Stomach with Ball between feet, Lying","UI_live_bar":{"ROM_Joint":4,"angle_type":"none","ROM_Angles_1":0,"ROM_Angles_2":110},"corr_angles":{"(1, 2)":[250,140,140,250],"(4, 3)":[250,140,140,250]},"main_muscle":[1,2],"threshold_1":0.5,"threshold_2":3,"bad_threshold":0.15,"good_threshold":0.45}],
        64:[{"bias":320,"ex_id":64,"ex_name":"sit to stand supported","UI_live_bar":{"ROM_Joint":1,"angle_type":"none","ROM_Angles_1":90,"ROM_Angles_2":20},"corr_angles":{"(1, 2)":[40,130,130,40],"(3, 4)":[230,320,320,230]},"main_muscle":[1,2],"threshold_1":0.7,"threshold_2":4,"bad_threshold":0.1,"good_threshold":0.3}],
        112:[{"bias":0,"ex_id":112,"ex_name":"Hip Abduction, Advanced, Standing","UI_live_bar":{"ROM_Joint":3,"angle_type":"abduction","ROM_Angles_1":15,"ROM_Angles_2":50},"corr_angles":{"(5, 3)":[75,40,40,75]},"main_muscle":[5,3],"threshold_1":0.7,"threshold_2":3,"bad_threshold":0.15,"good_threshold":0.3},{"bias":0,"ex_id":112,"ex_name":"Hip Abduction, Advanced, Standing","UI_live_bar":{"ROM_Joint":2,"angle_type":"abduction","ROM_Angles_1":15,"ROM_Angles_2":50},"corr_angles":{"(0, 2)":[110,145,145,110]},"main_muscle":[0,2],"threshold_1":0.7,"threshold_2":3,"bad_threshold":0.15,"good_threshold":0.3}],
        161:[{"bias":0,"ex_id":161,"ex_name":"Climbing up a Step, Single Leg Support","UI_live_bar":{"ROM_Joint":4,"angle_type":"none","ROM_Angles_1":70,"ROM_Angles_2":0},"corr_angles":{"(3, 4)":[215,280,280,215]},"main_muscle":[3,4],"threshold_1":0.5,"threshold_2":3,"bad_threshold":0.17,"good_threshold":0.3},{"bias":0,"ex_id":161,"ex_name":"Climbing up a Step, Single Leg Support","UI_live_bar":{"ROM_Joint":1,"angle_type":"none","ROM_Angles_1":70,"ROM_Angles_2":0},"corr_angles":{"(1, 2)":[20,100,100,20]},"main_muscle":[1,2],"threshold_1":0.5,"threshold_2":3,"bad_threshold":0.17,"good_threshold":0.3}],
        182:[{"bias":0,"ex_id":182,"ex_name":"Kneeling Hip Extension","UI_live_bar":{"ROM_Joint":1,"angle_type":"none","ROM_Angles_1":160,"ROM_Angles_2":100},"corr_angles":{"(1, 2)":[30,85,85,30]},"main_muscle":[1,2],"threshold_1":0.5,"threshold_2":10,"bad_threshold":0.15,"good_threshold":0.45}],
        204:[{"ex_name":"active SLR","ex_id":204,"UI_live_bar":{"ROM_Joint":4,"angle_type":"none","ROM_Angles_1":0,"ROM_Angles_2":80},"bias":200,"good_threshold":0.28,"bad_threshold":0.09,"main_muscle":[3,4],"threshold_1":0.7,"threshold_2":4,"corr_angles":{"(3, 4)":[160,240,240,240,160]}}],
        205:[{"bias":300,"ex_id":205,"ex_name":"mini-squats-supported","UI_live_bar":{"ROM_Joint":1,"angle_type":"none","ROM_Angles_1":0,"ROM_Angles_2":70},"corr_angles":{"(1, 2)":[160,100,100,160]},"main_muscle":[1,2],"threshold_1":0.6,"threshold_2":4,"bad_threshold":0.16,"good_threshold":0.5}],
        206:[{"bias":0,"ex_id":206,"ex_name":"Knee flexion in standing","UI_live_bar":{"ROM_Joint":1,"angle_type":"none","ROM_Angles_1":0,"ROM_Angles_2":90},"corr_angles":{"(0, 1)":[100,190,190,100]},"main_muscle":[0,1],"threshold_1":0.5,"threshold_2":3,"bad_threshold":0.07,"good_threshold":0.3},{"bias":100,"ex_id":206,"ex_name":"Knee flexion in standing","UI_live_bar":{"ROM_Joint":4,"angle_type":"none","ROM_Angles_1":0,"ROM_Angles_2":90},"corr_angles":{"(4, 5)":[190,270,270,190]},"main_muscle":[4,5],"threshold_1":0.5,"threshold_2":3,"bad_threshold":0.07,"good_threshold":0.3}],
        209:[{"bias":100,"ex_id":209,"ex_name":"pelvic lift with ball","UI_live_bar":{"ROM_Joint":2,"angle_type":"none","ROM_Angles_1":140,"ROM_Angles_2":110},"corr_angles":{"(1, 2)":[200,230,230,200],"(2, 12)":[260,230,230,260]},"main_muscle":[1,2],"threshold_1":0.5,"threshold_2":10,"bad_threshold":0.15,"good_threshold":0.45}],
        212:[{"bias":320,"ex_id":212,"ex_name":"sit to stand high","UI_live_bar":{"ROM_Joint":1,"angle_type":"none","ROM_Angles_1":90,"ROM_Angles_2":0},"corr_angles":{"(1, 2)":[60,130,130,60],"(3, 4)":[250,320,320,250]},"main_muscle":[1,2],"threshold_1":0.5,"threshold_2":4,"bad_threshold":0.11,"good_threshold":0.3}],
        220:[{"bias":0,"ex_id":220,"ex_name":"forward step climb","UI_live_bar":{"ROM_Joint":4,"angle_type":"none","ROM_Angles_1":50,"ROM_Angles_2":10},"corr_angles":{"(3, 4)":[205,275,275,205]},"main_muscle":[3,4],"threshold_1":0.9,"threshold_2":4,"bad_threshold":0.19,"good_threshold":0.35},{"bias":0,"ex_id":220,"ex_name":"forward step climb","UI_live_bar":{"ROM_Joint":1,"angle_type":"none","ROM_Angles_1":60,"ROM_Angles_2":10},"corr_angles":{"(1, 2)":[15,95,95,15]},"main_muscle":[1,2],"threshold_1":0.9,"threshold_2":4,"bad_threshold":0.19,"good_threshold":0.35}],
        225:[{"bias": 340, "ex_id": 225, "ex_name": "Lunge with a Step Forward", "UI_live_bar": {"ROM_Joint": 4, "angle_type": "flextion", "ROM_Angles_1": 0, "ROM_Angles_2": 90}, "corr_angles": {"(3,5)": [285, 330, 330, 285], "(0, 1)": [100, 10, 10, 100]}, "main_muscle": [0, 1], "threshold_1": 0.9, "threshold_2": 3, "bad_threshold": 0.09, "good_threshold": 0.3}, {"bias": 340, "ex_id": 225, "ex_name": "Lunge with a Step Forward", "UI_live_bar": {"ROM_Joint": 1, "angle_type": "flextion", "ROM_Angles_1": 0, "ROM_Angles_2": 90}, "corr_angles": {"(4,5)": [285, 185, 185, 285], "(1, 2)": [95, 195, 195, 95]}, "main_muscle": [1, 2], "threshold_1": 0.9, "threshold_2": 3, "bad_threshold": 0.09, "good_threshold": 0.3}],
        226:[{"bias":0,"ex_id":226,"ex_name":"Knee Flexion with a Weight On, Standing","UI_live_bar":{"ROM_Joint":1,"angle_type":"none","ROM_Angles_1":0,"ROM_Angles_2":90},"corr_angles":{"(0, 1)":[100,190,190,100]},"main_muscle":[0,1],"threshold_1":0.5,"threshold_2":3,"bad_threshold":0.07,"good_threshold":0.3},{"bias":100,"ex_id":226,"ex_name":"Knee Flexion with a Weight On, Standing","UI_live_bar":{"ROM_Joint":4,"angle_type":"none","ROM_Angles_1":0,"ROM_Angles_2":90},"corr_angles":{"(4, 5)":[190,270,270,190]},"main_muscle":[4,5],"threshold_1":0.5,"threshold_2":3,"bad_threshold":0.07,"good_threshold":0.3}],
        227:[{"bias": 300, "ex_id": 227, "ex_name": "Heel Raising", "UI_live_bar": {"ROM_Joint": 21, "angle_type": "none", "ROM_Angles_1": 0, "ROM_Angles_2": 30}, "corr_angles": {"(21,23)": [60, 90, 90, 60], "(22,24)": [60, 90, 90, 60],"(0,1)": [160,165,165,160]}, "main_muscle": [21, 23], "threshold_1": 2, "threshold_2": 3, "bad_threshold": 0.09, "good_threshold": 0.3}],
        235:[{"bias": 0, "ex_id": 235, "ex_name": "full range shoulder", "UI_live_bar": {"ROM_Joint": 12, "angle_type": "none", "ROM_Angles_1": 5, "ROM_Angles_2": 150}, "corr_angles":{ "(13, 14)": [260, 110, 110, 260],"(11, 12)": [110, 260, 260, 110]}, "main_muscle": [13, 14], "threshold_1": 0.3, "threshold_2": 7, "bad_threshold": 0.04, "good_threshold": 0.16}],
        237:[ { "bias": 0, "ex_id": 237, "ex_name": "march in place-right", "UI_live_bar": { "ROM_Joint": 4, "angle_type": "none", "ROM_Angles_1": 0, "ROM_Angles_2": 90 }, "corr_angles": { "(3, 4)": [ 280, 200, 200, 280 ] }, "main_muscle": [ 3, 4 ], "threshold_1": 1, "threshold_2": 3, "bad_threshold": 0.07, "good_threshold": 0.23 }, { "bias": 300, "ex_id": 237, "ex_name": "march in place-left", "UI_live_bar": { "ROM_Joint": 1, "angle_type": "none", "ROM_Angles_1": 0, "ROM_Angles_2": 90 }, "corr_angles": { "(1, 2)": [ 150, 70, 70, 150 ] }, "main_muscle": [ 1, 2 ], "threshold_1": 1, "threshold_2": 3, "bad_threshold": 0.07, "good_threshold": 0.23 } ],
        239:[{"ex_name":"deep squat","ex_id":239,"UI_live_bar":{"ROM_Joint":4,"angle_type":"none","ROM_Angles_1":0,"ROM_Angles_2":90},"bias":0,"good_threshold":0.3,"bad_threshold":0.07,"main_muscle":[1,2],"threshold_1":0.5,"threshold_2":3,"corr_angles":{"(1, 2)":[90,10,10,90]}}],
        252:[{"bias":0,"ex_id":268,"ex_name":"Pelvic Lift on a Balance Ball, Knees Bent","UI_live_bar":{"ROM_Joint":12,"angle_type":"flexion","ROM_Angles_1":0,"ROM_Angles_2":40},"corr_angles":{"(2, 12)":[0,40,40,0],"(2,1)":[90,60,60,90]},"main_muscle":[2,12],"threshold_1":0.5,"threshold_2":5,"bad_threshold":0.15,"good_threshold":0.45}],
        253:[{"bias": 0, "ex_id": 253, "ex_name": "wide squat", "UI_live_bar": {"ROM_Joint": 4, "angle_type": "none", "ROM_Angles_1": 0, "ROM_Angles_2": 90}, "corr_angles": {"(3, 4)": [250, 190, 190, 250], "(1,2)": [105, 175, 175, 105]}, "main_muscle": [3, 4], "threshold_1": 0.5, "threshold_2": 3, "bad_threshold": 0.2, "good_threshold": 0.3}],
        254:[{"bias":300,"ex_id":254,"ex_name":"long arch quad","UI_live_bar":{"ROM_Joint":1,"angle_type":"none","ROM_Angles_1":110,"ROM_Angles_2":20},"corr_angles":{"(0, 1)":[170,85,85,170],"(1, 2)":[80,70,70,80]},"main_muscle":[0,1],"threshold_1":2,"threshold_2":5,"bad_threshold":0.11,"good_threshold":0.5}],
        262:[{"bias":340,"ex_id":262,"ex_name":"Basic Lunge","UI_live_bar":{"ROM_Joint":4,"angle_type":"flextion","ROM_Angles_1":0,"ROM_Angles_2":90},"corr_angles":{"(3,5)":[285,330,330,285],"(0, 1)":[75,10,10,75]},"main_muscle":[3,5],"threshold_1":0.7,"threshold_2":3,"bad_threshold":0.09,"good_threshold":0.3},{"bias":340,"ex_id":262,"ex_name":"Basic Lunge","UI_live_bar":{"ROM_Joint":1,"angle_type":"flextion","ROM_Angles_1":0,"ROM_Angles_2":90},"corr_angles":{"(4,5)":[260,185,185,260],"(1, 2)":[125,195,195,125]},"main_muscle":[4,5],"threshold_1":0.7,"threshold_2":3,"bad_threshold":0.09,"good_threshold":0.3}],
        266:[{"bias":250,"ex_id":266,"ex_name":"Knee extensors no resistance","UI_live_bar":{"ROM_Joint":1,"angle_type":"none","ROM_Angles_1":110,"ROM_Angles_2":0},"corr_angles":{"(0, 1)":[220,135,135,220],"(1, 2)":[130,120,120,130]},"main_muscle":[0,1],"threshold_1":2,"threshold_2":5,"bad_threshold":0.11,"good_threshold":0.5}],
        268:[{"bias":0,"ex_id":268,"ex_name":"Knee Flexion/Extension with Legs on a Balance Ball","UI_live_bar":{"ROM_Joint":2,"angle_type":"flexion","ROM_Angles_1":50,"ROM_Angles_2":120},"corr_angles":{"(1, 2)":[330,230,230,330],"(0,1)":[150,30,30,150]},"main_muscle":[1,2],"threshold_1":0.5,"threshold_2":5,"bad_threshold":0.15,"good_threshold":0.45}],
        292:[{"ex_name":"bridging","ex_id":292,"UI_live_bar":{"ROM_Joint":2,"angle_type":"extension","ROM_Angles_1":0,"ROM_Angles_2":30},"bias":0,"good_threshold":0.3,"bad_threshold":0.07,"main_muscle":[3,4],"threshold_1":0.7,"threshold_2":4,"corr_angles":{"(3, 4)":[100,130,130,100]}}],
        293:[{"bias":0,"ex_id":293,"ex_name":"Step â€“ Quadruped A","UI_live_bar":{"ROM_Joint":21,"angle_type":"none","ROM_Angles_1":0,"ROM_Angles_2":30},"corr_angles":{"(2,1)":[280,170,170,280],"(0,1)":[280,90,90,280],"(2,12)":[160,155,155,160]},"main_muscle":[2,1],"threshold_1":2,"threshold_2":3,"bad_threshold":0.09,"good_threshold":0.3}],
        300:[{"bias": 100, "ex_id": 300, "ex_name": "pwr - sit to stand", "UI_live_bar": {"ROM_Joint": 4, "angle_type": "none", "ROM_Angles_1":90, "ROM_Angles_2": 0}, "corr_angles": {"(3, 4)": [260, 170, 170, 260]}, "main_muscle": [3, 4], "threshold_1": 0.5, "threshold_2": 4, "bad_threshold": 0.1, "good_threshold": 0.3}],
        316:[{"bias":300,"ex_id":316,"ex_name":"pwr squat","UI_live_bar":{"ROM_Joint":4,"angle_type":"none","ROM_Angles_1":0,"ROM_Angles_2":70},"corr_angles":{"(1, 2)":[160,90,90,160]},"main_muscle":[1,2],"threshold_1":0.5,"threshold_2":3,"bad_threshold":0.08,"good_threshold":0.3}],
        325:[{"bias":0,"ex_id":325,"ex_name":"single leg stance","UI_live_bar":{"ROM_Joint":1,"angle_type":"none","ROM_Angles_1":0,"ROM_Angles_2":90},"corr_angles":{"(0, 1)":[100,190,190,100]},"main_muscle":[0,1],"threshold_1":0.5,"threshold_2":3,"bad_threshold":0.07,"good_threshold":0.3},{"bias":100,"ex_id":325,"ex_name":"single leg stance","UI_live_bar":{"ROM_Joint":4,"angle_type":"none","ROM_Angles_1":0,"ROM_Angles_2":90},"corr_angles":{"(4, 5)":[190,270,270,190]},"main_muscle":[4,5],"threshold_1":0.5,"threshold_2":3,"bad_threshold":0.07,"good_threshold":0.3}],
        346:[{"bias": 320, "ex_id": 346, "ex_name": "rows", "UI_live_bar": {"ROM_Joint": 11, "angle_type": "none", "ROM_Angles_1": 0, "ROM_Angles_2": 90}, "corr_angles": {"(11, 12)": [50, 140, 140, 50], "(13, 14)": [230, 320, 320, 230]}, "main_muscle": [11, 12], "threshold_1":2, "threshold_2": 3, "bad_threshold": 0.15, "good_threshold": 0.3}],
        458:[{"bias":0,"ex_id":458,"ex_name":"Back Extension","UI_live_bar":{"ROM_Joint":6,"angle_type":"none","ROM_Angles_1":10,"ROM_Angles_2":30},"corr_angles":{"(2,12)":[90,65,65,90]},"main_muscle":[2,12],"threshold_1":0.7,"threshold_2":3,"bad_threshold":0.2,"good_threshold":0.8}],
        476:[{"bias":0,"ex_id":476,"ex_name":"Shoulder Abduction with a Dumbbell","UI_live_bar":{"ROM_Joint":13,"angle_type":"abduction","ROM_Angles_1":0,"ROM_Angles_2":80},"corr_angles":{"(13, 14)":[260,180,180,260]},"main_muscle":[13,14],"threshold_1":0.7,"threshold_2":3.5,"bad_threshold":0.15,"good_threshold":0.4}],
        480:[{"bias":200,"ex_id":480,"ex_name":"shoulder flextion ROM","UI_live_bar":{"ROM_Joint":13,"angle_type":"flexion","ROM_Angles_1":170,"ROM_Angles_2":45},"corr_angles":{"(13, 14)":[260,90,90,260]},"main_muscle":[13,14],"threshold_1":0.5,"threshold_2":5,"bad_threshold":0.08,"good_threshold":0.2}],
        481:[{"bias":0,"ex_id":481,"ex_name":"Excentric Elbow extension","UI_live_bar":{"ROM_Joint":1,"angle_type":"none","ROM_Angles_1":0,"ROM_Angles_2":90},"corr_angles":{"(14, 15)":[150,50,50,150],"(3, 13)":[40,50,50,40]},"main_muscle":[14,15],"threshold_1":0.5,"threshold_2":3,"bad_threshold":0.07,"good_threshold":0.3}],
        491:[{"bias": 0, "ex_id":491, "ex_name": "Sit to Stand on One Leg", "UI_live_bar": {"ROM_Joint": 4, "angle_type": "none", "ROM_Angles_1": 70, "ROM_Angles_2": 0}, "corr_angles": {"(3, 4)": [210, 270, 270, 210], "(2, 0)": [210, 240, 240, 210]}, "main_muscle": [3, 4], "threshold_1": 1.5, "threshold_2": 4, "bad_threshold": 0.13, "good_threshold": 0.3}],
        495:[{"bias":0,"ex_id":495,"ex_name":"Stair Calf Raises","UI_live_bar":{"ROM_Joint":21,"angle_type":"none","ROM_Angles_1":0,"ROM_Angles_2":30},"corr_angles":{"(21,23)":[180,150,150,180],"(22,24)":[180,150,150,180],"(1,0)":[270,265,265,270]},"main_muscle":[21,23],"threshold_1":2,"threshold_2":3,"bad_threshold":0.09,"good_threshold":0.3}],
        499:[{"bias":150,"ex_id":499,"ex_name":"Single Leg Stretch, Pilates - left","UI_live_bar":{"ROM_Joint":3,"angle_type":"none","ROM_Angles_1":140,"ROM_Angles_2":110},"corr_angles":{"(3, 4)":[230,320,320,230]},"main_muscle":[3,4],"threshold_1":0.5,"threshold_2":3,"bad_threshold":0.15,"good_threshold":0.45},{"bias":150,"ex_id":499,"ex_name":"Single Leg Stretch, Pilates - right","UI_live_bar":{"ROM_Joint":2,"angle_type":"none","ROM_Angles_1":140,"ROM_Angles_2":110},"corr_angles":{"(1, 2)":[60,150,150,60]},"main_muscle":[1,2],"threshold_1":0.5,"threshold_2":3,"bad_threshold":0.15,"good_threshold":0.45}],
        505:[{"bias":0,"ex_id":505,"ex_name":"inchworm","UI_live_bar":{"ROM_Joint":2,"angle_type":"none","ROM_Angles_1":0,"ROM_Angles_2":70},"corr_angles":{"(2, 12)":[90,210,195,160,195,210,90]},"main_muscle":[2,12],"threshold_1":2,"threshold_2":8,"bad_threshold":0.07,"good_threshold":0.3}],
    }
}